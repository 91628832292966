import React, {Fragment} from 'react'
import {Provider} from 'react-redux'
import Loadable from 'react-loadable'
import {Router, Switch, Route} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {LocaleProvider} from 'antd'
import locale from 'antd/lib/locale-provider/zh_CN'

import {APP_NAME, APP_VERSION, APP_DESCRIPTION, APP_SHA} from 'services/constants'
import store from 'services/store'
import history from 'services/history'

const Main = Loadable({
  loading: () => null,
  loader: () => import('scenes/Main')
})

function App() {
  return (
    <LocaleProvider locale={locale} >
      <Provider store={store} >
        <Router history={history} >
          <Fragment>
            <Helmet>
              <meta
                name="app-meta"
                content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
              />
            </Helmet>
            <Switch>
              <Route
                path="/"
                component={Main}
              />
            </Switch>
          </Fragment>
        </Router>
      </Provider>
    </LocaleProvider>
  )
}

export default App
